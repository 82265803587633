import {Token, TokenType} from "@/components/Perps/types";
import {blast, mainnet, sepolia} from "wagmi/chains";
import {AddressZero} from "@ethersproject/constants";

export interface TokenInfo extends Token {
  isVault: boolean;
}

export interface TokenData {
  eth: TokenInfo;
  weth: TokenInfo;
  wWeth: TokenInfo;
  usd: TokenInfo;
  wUsd: TokenInfo;
}

interface TokenConstants {
  [chainId: string]: TokenData;
}

export const tokenConstants: TokenConstants = {
  [mainnet.id]: {
    eth: {
      chain: mainnet.name,
      chainId: mainnet.id,
      tokenType: TokenType.ERC20,
      name: "Ether",
      symbol: "ETH",
      address: AddressZero,
      decimals: 18,
      nftAddress: "",
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/weth.png",
      isVault: false
    },
    weth: {
      chain: mainnet.name,
      chainId: mainnet.id,
      nftAddress: "",
      tokenType: TokenType.ERC20,
      name: "Wrapped Ether",
      symbol: "WETH",
      address: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
      decimals: 18,
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/weth.png",
      isVault: false
    },
    wWeth: {
      chain: mainnet.name,
      chainId: mainnet.id,
      nftAddress: "",
      tokenType: TokenType.ERC20,
      name: "Spicy Wrapped Ether",
      symbol: "sETH",
      address: "0x630ED8220F9cbc98358a2E2eCb0727D7B9D61397",
      decimals: 18,
      imageUrl: "/static/sETH.svg",
      isVault: true
    },
    usd: {
      chain: mainnet.name,
      chainId: mainnet.id,
      tokenType: TokenType.ERC20,
      name: "USD Coin",
      symbol: "USDC",
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      decimals: 6,
      nftAddress: "",
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/usdc.png",
      isVault: false
    },
    wUsd: {
      chain: mainnet.name,
      chainId: mainnet.id,
      tokenType: TokenType.ERC20,
      name: "Spicy USDC",
      symbol: "sUSDC",
      address: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48", // TODO: replace with USDC vault once deployed on mainnet
      decimals: 6,
      nftAddress: "",
      imageUrl: "/static/sUSDC.svg",
      isVault: true
    }
  },
  [blast.id]: {
    eth: {
      chain: blast.name,
      chainId: blast.id,
      tokenType: TokenType.ERC20,
      name: "Ether",
      symbol: "ETH",
      address: AddressZero,
      decimals: 18,
      nftAddress: "",
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/weth.png",
      isVault: false
    },
    weth: {
      chain: blast.name,
      chainId: blast.id,
      nftAddress: "",
      tokenType: TokenType.ERC20,
      name: "Wrapped Ether",
      symbol: "WETH",
      address: "0x4300000000000000000000000000000000000004",
      decimals: 18,
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/weth.png",
      isVault: false
    },
    wWeth: {
      chain: blast.name,
      chainId: blast.id,
      nftAddress: "",
      tokenType: TokenType.ERC20,
      name: "Spicy Wrapped Ether",
      symbol: "sETH",
      address: "0x8E2b50413a53F50E2a059142a9be060294961e40",
      decimals: 18,
      imageUrl: "/static/sETH.svg",
      isVault: true
    },
    usd: {
      chain: blast.name,
      chainId: blast.id,
      tokenType: TokenType.ERC20,
      name: "USDB",
      symbol: "USDB",
      address: "0x4300000000000000000000000000000000000003",
      decimals: 18,
      nftAddress: "",
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/usdb.png",
      isVault: false
    },
    wUsd: {
      chain: blast.name,
      chainId: blast.id,
      tokenType: TokenType.ERC20,
      name: "Spicy USDB",
      symbol: "sUSDB",
      address: "0x4BeD2A922654cAcC2Be974689619768FaBF24855",
      decimals: 18,
      nftAddress: "",
      imageUrl: "/static/sUSDB.svg",
      isVault: true
    }
  },
  [sepolia.id]: {
    eth: {
      chain: sepolia.name,
      chainId: sepolia.id,
      tokenType: TokenType.ERC20,
      name: "Ether",
      symbol: "ETH",
      address: AddressZero,
      decimals: 18,
      nftAddress: "",
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/weth.png",
      isVault: false
    },
    weth: {
      chain: sepolia.name,
      chainId: sepolia.id,
      nftAddress: "",
      tokenType: TokenType.ERC20,
      name: "Wrapped Ether",
      symbol: "WETH",
      address: "0x6400c43e5dd1f713fd623d92dc64831dd12d3298",
      decimals: 18,
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/weth.png",
      isVault: false
    },
    wWeth: {
      chain: sepolia.name,
      chainId: sepolia.id,
      nftAddress: "",
      tokenType: TokenType.ERC20,
      name: "Spicy Wrapped Ether",
      symbol: "sETH",
      address: "0x885b8F761A72499e697380DC12b194837d44113e",
      decimals: 18,
      imageUrl: "/static/sETH.svg",
      isVault: true
    },
    usd: {
      chain: sepolia.name,
      chainId: sepolia.id,
      tokenType: TokenType.ERC20,
      name: "USD Coin",
      symbol: "USDC",
      address: "0x92ea09e6f1cc933baac19cd6414b64a9d84cc135",
      decimals: 6,
      nftAddress: "",
      imageUrl: "https://wasabi-public.s3.amazonaws.com/tokens/usdc.png",
      isVault: false
    },
    wUsd: {
      chain: sepolia.name,
      chainId: sepolia.id,
      tokenType: TokenType.ERC20,
      name: "Spicy USDC",
      symbol: "sUSDC",
      address: "0xBB0C7abb302264a76056F63f0b590B4A943fB110",
      decimals: 6,
      nftAddress: "",
      imageUrl: "/static/sUSDC.svg",
      isVault: true
    }
  }
}