import {Address, zeroAddress} from "viem";
import postData from "@/api/postRequest";
import {
  ClosePositionRequest,
  CloseQuoteResponse,
  DexScreenerPair,
  OpenPositionRequest,
  PerpConfig,
  PerpOrder,
  PerpPositionStatus,
  PerpQuoteResponseV2,
  PerpQuoteRequest,
  PerpQuoteRequestV2,
  PositionTradeHistory,
  VaultStats,
  PerpWhiteList,
  OpenInterest,
  LPStats,
  AdminStatsCount,
  AdminLpHistory,
  OpenInterestStats,
  UserStats,
  LeaderboardItem,
  OpenInterestOverview,
  BannerShareData,
  InfoMessage,
  SwapConfig,
  SwapQuote,
  PerpConfig2,
  UserBlastPointsStats,
  BlastGoldUser,
  Market,
  MarketStats,
  MarketStatsList,
  PositionStatus,
  SwapRequest,
  SwapQuoteV2,
  ActionType,
  DailyStats,
  BlastGoldClaim, LPVaultSummary, BlastinPepesWhitelistData, TradeCompetitionStats,
  ClosePositionOrder, CancelOrder,
  TradeHistoryFilter,
} from "@/components/Perps/types";
import {OptionalDataResponse, PaginatedResponse, StatusResponse, TransactionType, WithMessage} from "@/types/types";
import {convertOptionalDataResponse, convertPaginatedResponse} from "@/util/responseConverters";
import {PerpSide} from "@/components/Perps/openPosition/PerpSideSelect";
// import {TokenStatsResponse} from "@/components/Perps/types";

const BASE_URL = process.env.NEXT_PUBLIC_BACKEND_API;

export const fetchMarketStats = async (marketPairId: number): Promise<MarketStats> => {
  const response = await fetch(`${BASE_URL}/api/market/stats/${marketPairId}`);
  return await response.json();
}

export const fetchQuote = async (
  marketPairId: number, side: PerpSide, downPayment: bigint, leverage: number, maxSlippage: number, speedUp: boolean
): Promise<PerpQuoteResponseV2> => {
  const params = new URLSearchParams();
  params.append('marketId', marketPairId.toString());
  params.append('side', side.toUpperCase());
  params.append('downPayment', downPayment.toString());
  params.append('leverage', leverage.toString());
  params.append('maxSlippage', maxSlippage.toString());
  params.append('speedUp', speedUp.toString());

  const response = await fetch(`${BASE_URL}/api/market/quote?${params}`);
  return await response.json();
}

export const fetchSwapQuote = async (swapConfig: SwapConfig): Promise<SwapQuote> => {
  return await postData(`${BASE_URL}/api/swap`, swapConfig);
}

export const fetchSwapQuoteV2 = async (swapRequest: SwapRequest): Promise<SwapQuoteV2> => {
  return await postData(`${BASE_URL}/api/v2/swap`, swapRequest);
}

export const fetchBalances = async (address: Address, tokens: Address[]): Promise<bigint[]> => {
  const response = await fetch(`${BASE_URL}/api/balances/${address}?tokens=${tokens.join(',')}`);
  return await response.json();
}

export const fetchCloseQuote = async (positionId: number, maxSlippage: number, eject: boolean): Promise<CloseQuoteResponse> => {
  const urlParams = new URLSearchParams();
  urlParams.append('maxSlippage', maxSlippage.toString());
  urlParams.append('eject', eject.toString());
  const response = await fetch(`${BASE_URL}/api/market/quote/close/${positionId}?${urlParams}`);
  return await response.json();
}

export const fetchPerpConfigs2 = async (backendURL?: string): Promise<PaginatedResponse<PerpConfig2>> => {
  const response = await fetch(`${backendURL ? backendURL : BASE_URL}/api/tokens/list`);
  return await response.json();
}

export const fetchMarketStatsList = async (backendURL?: string): Promise<PaginatedResponse<MarketStatsList>> => {
  const response = await fetch(`${backendURL ? backendURL : BASE_URL}/api/market/stats/list`);
  return await response.json();
}

export const fetchMarket = async (idOrName: string): Promise<Market> => {
  const response = await fetch(`${BASE_URL}/api/market/${idOrName}`);
  return await response.json();
}

export const fetchPositions = async (address: string): Promise<PositionStatus[]> => {
  const response = await fetch(`${BASE_URL}/api/v2/positions/${address}`);
  return await response.json();
}

export const getTradeStatusDownloadImageLink = (positionId: string) => {
  return `${BASE_URL}/api/position/tradeStatusImage/download/${positionId}`;
}

export const getVaultDepositDownloadImageLink = (filename: string) => {
  return `${BASE_URL}/api/stats/lp/share/download/${filename}`;
}

export const getGoldDownloadImageLink = (fileName: string) => {
  return `${BASE_URL}/api/gold/share/${fileName}`;
}

export const fetchBannerShareData = async (positionId: number): Promise<OptionalDataResponse<BannerShareData>> => {
  let data = await fetch(`${BASE_URL}/api/position/shareData/${positionId}`);
  data = await data.json();
  return convertOptionalDataResponse(data, a => a);
};

export const fetchOrder = async (request: PerpQuoteRequest): Promise<PerpOrder<OpenPositionRequest>> => {
  return await postData(`${BASE_URL}/api/order/open`, {
    ...request,
    side: request.side.toUpperCase(),
  });
}

export const fetchOrderV2 = async (request: PerpQuoteRequestV2): Promise<PerpOrder<OpenPositionRequest>> => {
  return await postData(`${BASE_URL}/api/v2/order/open`, {
    ...request,
    side: request.side.toUpperCase(),
  });
}

export const fetchCloseOrder = async (positionId: number, maxSlippage: number, eject: boolean): Promise<PerpOrder<ClosePositionRequest>> => {
  return await postData(`${BASE_URL}/api/v2/order/close/${positionId}?maxSlippage=${maxSlippage}&eject=${eject}`);
}

export const fetchVaultStats = async (): Promise<PaginatedResponse<VaultStats>> => {
  const response = await fetch(`${BASE_URL}/api/vaults`);
  return await response.json();
}

export const fetchOpenInterestForToken = async (address: string): Promise<OpenInterest[]> => {
  const response = await fetch(`${BASE_URL}/api/stats/openInterest/${address}`);
  return await response.json();
}

export const fetchMarketOpenInterest = async (id: number): Promise<OpenInterest[]> => {
  const response = await fetch(`${BASE_URL}/api/market/openInterest/${id}`);
  return await response.json();
}

export const fetchOpenInterestStats = async (): Promise<OpenInterestOverview> => {
  const response = await fetch(`${BASE_URL}/api/v2/admin/openInterestStats`);
  return await response.json();
}

export const fetchLPStats = async (address: string, vaultAddress: string): Promise<LPStats> => {
  if (address === zeroAddress) {
    return { earned: 0n, initialDepositTimestamp: new Date(), totalDeposited: 0n, pendingInterest: 0n, streakMultiplier: 1, nextStreakTimestamp: undefined };
  }
  const response = await fetch(`${BASE_URL}/api/stats/lp/${address}/${vaultAddress}`);
  return await response.json();
}

export const fetchLPStatsShare = async (address: string, vaultAddress: string): Promise<OptionalDataResponse<BannerShareData>> => {
  let data = await fetch(`${BASE_URL}/api/stats/lp/${address}/${vaultAddress}/share`);
  data = await data.json();
  return convertOptionalDataResponse(data, a => a);
}

//Admin endpoints

export const fetchAdminPositions = async (nextPageToken?: string, tokenAddress?: string , side?: string): Promise<PaginatedResponse<PositionStatus>> => {
  const params = new URLSearchParams();
  if (nextPageToken) {
    params.set('offset', nextPageToken);
  }
  if (tokenAddress) {
    params.set('tokenAddress', tokenAddress);
  }
  if (side) {
    params.set('side', side);
  }

  let data = await fetch(`${BASE_URL}/api/admin/positions/?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, a => a);
}
export const fetchRiskyPositions = async (nextPageToken?: string, tokenAddress?: string , side?: string): Promise<PaginatedResponse<PositionStatus>> => {
  const params = new URLSearchParams();
  if (nextPageToken) {
    params.set('offset', nextPageToken);
  }
  if (tokenAddress) {
    params.set('tokenAddress', tokenAddress);
  }
  if (side) {
    params.set('side', side);
  }

  let data = await fetch(`${BASE_URL}/api/admin/riskyPositions/?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, a => a);
}

export const fetchPositionTradeHistory = async (filter: TradeHistoryFilter): Promise<PaginatedResponse<PositionTradeHistory>> => {
  const params = new URLSearchParams();
  if (filter.nextPageToken) {
    params.set('offset', filter.nextPageToken);
  }
  if (filter.type) {
    params.set('type', filter.type);
  }
  if (filter.tokenAddress) {
    params.set('tokenAddress', filter.tokenAddress);
  }
  if (filter.traderAddress) {
    params.set('traderAddress', filter.traderAddress);
  }
  if (filter.marketId) {
    params.set('marketId', filter.marketId.toString());
  }

  let data = await fetch(`${BASE_URL}/api/tradeHistory/?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, a => a);
}

export const fetchAdminLpHistory = async (nextPageToken?: string, address?: string): Promise<PaginatedResponse<AdminLpHistory>> => {
  const params = new URLSearchParams();
  if (nextPageToken) {
    params.set('offset', nextPageToken);
  }
  if (address) {
    params.set('address', address);
  }

  let data = await fetch(`${BASE_URL}/api/admin/lp/history/?${params}`);
  data = await data.json();
  return convertPaginatedResponse(data, a => a);
}

export const fetchAdminStatsCount = async (): Promise<AdminStatsCount> => {
  const response = await fetch(`${BASE_URL}/api/admin/statsCount`);
  return await response.json();
}

export const fetchETHPrice = async (): Promise<number> => {
  return await fetch(`${BASE_URL}/api/ethPrice`).then(res => res.json()).then(res => res.USD)
}
export const savePerpUserReferral = async (address?: string, code?: string | null): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/userReferral`, {address, code});
};

export const fetchUserStats = async (address: string): Promise<UserStats> => {
  const response = await fetch(`${BASE_URL}/api/user/${address}/stats`);
  return await response.json();
}

export const fetchUserReferralCode = async (address: string): Promise<OptionalDataResponse<String>> => {
  const response = await fetch(`${BASE_URL}/api/user/referral/${address}`);
  return await response.json();
}
export const fetchPerpLeaderboard = async (nextPageToken: string, sortBy?: string): Promise<PaginatedResponse<LeaderboardItem>> => {
  const params = new URLSearchParams();
  if (nextPageToken) {
    params.set('offset', nextPageToken);
  }
  params.set('limit', '50');
  if (sortBy) {
    params.set('sortBy', sortBy);
  }

  const response = await fetch(`${BASE_URL}/api/leaderboard?${params}`);
  return await response.json();
}

export const fetchPerpInfoMessage = async (): Promise<InfoMessage[]> => {
  const response = await fetch(`${BASE_URL}/api/info/messages`);
  return await response.json();
}

export const fetchUserBlastPointsStats = async (address: string): Promise<UserBlastPointsStats> => {
  const response = await fetch(`${BASE_URL}/api/user/${address}/blastPoints/stats`);
  return await response.json();
}

export const fetchPassBalance = async (address: string): Promise<number> => {
  const response = await fetch(`${BASE_URL}/api/passes/${address}`);
  return await response.json();
}

export const fetchCheckPositionByTxHash = async (txHash: string, type: TransactionType): Promise<boolean> => {
    const response = await fetch(`${BASE_URL}/api/transactionCheck/${txHash}/${type}`);
    return await response.json();
}

export const fetchBlastGoldUser = async (address: string): Promise<OptionalDataResponse<BlastGoldUser>> => {
  const response = await fetch(`${BASE_URL}/api/user/${address}/gold`);
  return await response.json();
}

export const fetchBlastGold = async (address: string): Promise<OptionalDataResponse<BlastGoldClaim>> => {
  const response = await fetch(`${BASE_URL}/api/user/${address}/gold/stats`);
  return await response.json();
}
export const claimBlastGold = async (address: string): Promise<BlastGoldUser> => {
  const response = await fetch(`${BASE_URL}/api/user/${address}/gold/claim`);
  return await response.json();
}

export const fetchGoldShare = async (address: string): Promise<OptionalDataResponse<BannerShareData>> => {
  const response = await fetch(`${BASE_URL}/api/user/${address}/gold/share`);
  return await response.json();
};

export const fetchCheckBoostForUser = async (address: string, slug: string): Promise<boolean> => {
  const response = await fetch(`${BASE_URL}/api/boost/${address}/${slug}`);
  return await response.json();
}

export const activateBoostForUser = async (address: string, slug: string): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/boost/activate`, {address, slug});
}

export const fetchDailyStats = async (): Promise<DailyStats[]> => {
  const response = await fetch(`${BASE_URL}/api/dailyStats`);
  return await response.json();
}

export const fetchLPVaultSummary = async (address: string): Promise<LPVaultSummary> => {
  const response = await fetch(`${BASE_URL}/api/${address}/lpsummary`);
  return await response.json();
}

export const fetchBlastinPepesWhitelist = async (address: string): Promise<OptionalDataResponse<BlastinPepesWhitelistData>> => {
  const response = await fetch(`https://backend-mainnet.wasabi.xyz/api/checkWhitelist/${address}`);
  return await response.json();
}
export const fetchTradingCompetitionStats = async (address?: string | undefined, onlyRealized: boolean | undefined = false): Promise<TradeCompetitionStats> => {
  const params = new URLSearchParams();
  if (address) {
    params.set('address', address);
  }
  if (onlyRealized !== undefined) {
    params.set('onlyRealized', onlyRealized.toString());
  }
  const response = await fetch(`${BASE_URL}/api/tradingCompetition/stats?${params}`);
  return await response.json();
}

export const fetchClosePositionOrder = async (positionId: number): Promise<ClosePositionOrder[]> => {
  const response = await fetch(`${BASE_URL}/api/limitOrder/${positionId}`);
  return await response.json();
}

export const setCloseLimitOrder = async (closePositionOrder: ClosePositionOrder, signature: string): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/limitOrder/close`, {
    data: closePositionOrder,
    signature
  });
}

export const cancelOrder = async (cancelOrder: CancelOrder, signature: string): Promise<StatusResponse> => {
  return await postData(`${BASE_URL}/api/limitOrder/cancel`, {
    data: cancelOrder,
    signature
  });
}
