import { BigNumber } from "ethers";
import {Ask, WasabiAsk} from "@/types/exchange_types";
import {Address} from "viem";
import {CollectionDetailStatic} from "@/types/dkoda_types";

export {};

export interface OnChangeFunction<T> {
  (value: T): any;
}

export enum OptionType {
  CALL = 0,
  PUT = 1,
}

export enum PayoutType {
  WRAPPED,
  UNWRAPPED,
  VAULT_DEPOSIT
}

export enum TransactionType {
  TRADE = "TRADE",
  LP = "LP",
}

export interface NFT {
  contractAddress?: string;
  tokenId: number | string;
  imageThumbnailUrl?: string;
}

export type NFTs = NFT[];

export interface DkodaPricingConfiguration {
  poolAddress: string;
  premiumMultiplierPercent: number;
  minStrike: BigNumber;
  maxStrike: BigNumber;
  minDuration: BigNumber;
  maxDuration: BigNumber;
  callEnabled: boolean;
  putEnabled: boolean;
  blockNumber: number;
}

export interface NewPoolRequestBody {
  nftAddress: string;
  tokenAddress?: string;
  initialTokenIds: BigNumber[];
  initialEthAmount: BigNumber;
  admin: string;
  pricingConfiguration: DkodaPricingConfiguration;
}

export interface WasabiPool {
  address: string;
  owner: string;
  admin: string;
  commodityAddress: string;
  availableBalance: BigNumber;
  totalBalance?: BigNumber;
  availableTokens: number;
  totalTokens?: number;
  tokenAddress?: string;
}

export interface WasabiPoolWithCollection {
  pool: WasabiPool;
  collection: Collection;
}

export interface OptionData {
  optionType: OptionType;
  strikePrice: BigNumber;
  premium: BigNumber;
  expiration: number;
}

export enum OptionStatus {
  ISSUED = "ISSUED" , EXPIRED = "EXPIRED", EXERCISED = "EXERCISED", BURNED = "BURNED"
}

export interface WasabiOption extends OptionData {
  id: string;
  poolAddress: string;
  tokenId?: BigNumber;
  status?: OptionStatus;
  originalPremium: BigNumber;
  collectionAddress: string,
}

export interface WasabiOptionWithMetadata extends WasabiOption {
  metadata: ERC721Metadata;
  owner?: string;
}

export interface WasabiOptionWithMetadataAndValue extends WasabiOptionWithMetadata {
  arbitragePayout: BigNumber;
  ask?: BigNumber;
  highestBid?: BigNumber;
  poolBid?: number;
  side: "SELL" | "BUY";
}

export interface WasabiOptionPage {
  option: WasabiOptionWithMetadata;
  collection: CollectionDetailStatic;
  floorPrice: BigNumber;
  payoutTransaction?: PayoutTransaction;
  tradeToShare?: OptionActivity;
}

export interface AvailableSelection {
  id: number;
  poolAddress: string;
  tokenAddress?: string | null;
  premium: BigNumber;
  tokenIds?: BigNumber[];
  ask?: WasabiAsk;
  numAvailable: number;
}

export interface AvailableOptions extends OptionData {
  selections: AvailableSelection[];
}

export enum OptionActivityType {
  ISSUE, TRANSFER, EXERCISE, BURN
}

export interface OptionActivity {
  hash: string;
  id: string;
  timestamp: number;
  sender: string | null;
  receiver: string | null;
  type: OptionActivityType;
}

export interface OptionActivityData {
  activity: OptionActivity;
  option: WasabiOption;
  symbol: string;
}

// A request to create an option on chain
export interface PoolAsk {
  id: number;
  poolAddress: string;
  optionType: OptionType;
  strikePrice: BigNumber;
  premium: BigNumber;
  expiry: number;
  tokenId: BigNumber;
  orderExpiry: number;
}

export interface PoolAskOrder {
  ask: PoolAsk;
  signature: string;
}

export interface PoolAskMigrated {
  id: bigint;
  poolAddress: Address;
  optionType: OptionType;
  strikePrice: bigint;
  premium: bigint;
  expiry: bigint;
  tokenId: bigint;
  orderExpiry: bigint;
}

export interface PoolAskOrderMigrated {
  ask: PoolAskMigrated;
  signature: string;
}

export interface ExpirationDate {
  date: number;
  numOptions: number;
}
export interface CollectionOptionsResponse {
  dates: number[];
  expirationDates: ExpirationDate[];
  selectedDate: number;
  floorPrice: BigNumber;
  options: AvailableOptions[];
}

export interface WasabiAskWithOption {
  ask: Ask,
  option: WasabiOption;
}

export interface FinalizeOptionResponse {
  availableOptions: AvailableOptions;
  collection: Collection;
  floorPrice: BigNumber;
  signature?: string;
  option?: PoolAsk;
  ask?: WasabiAskWithOption,
  tokenAddress?: string | null;
}

export interface Collection {
  id?: string;
  collectionId?: string | null | undefined;
  contractAddress: string;
  name: string;
  imageThumbnailUrl?: string;
  range?: string;
}

export interface CollectionPoolData {
  floorPrice: BigNumber; // WEI
  marketplace?: string;
  poolCount: number;
  issuedOptionCount: number;
  totalAssetCount: number;
  availableAssetCount: number;
  askCount: number;
  totalValueOfPools: BigNumber; // WEI
  totalValueAvailable: BigNumber; // WEI
  numAvailableCalls: number;
  numAvailablePuts: number;
  totalAvailableLoanSize: BigNumber;
}

export interface CollectionWithPoolData {
  collection: Collection;
  poolData: CollectionPoolData;
  stats: CollectionStats;
}

export interface CollectionStats {
  floorAsk: FloorAsk;
  volume: Volume;
  volumeChange: VolumeChange;
  floorSale: FloorSale;
  floorSaleChange: FloorSaleChange;
}

export interface FloorAsk {
  price?: {
    currency: {
      contract: string;
      name: string;
      symbol: string;
      decimals: number;
    };
    amount: {
      raw: BigNumber;
      decimal: number;
      usd: number;
      native: number;
    };
  };
}

export interface Volume {
  '1day': number;
  '7day': number;
  '30day': number;
  allTime: number;
}

export interface VolumeChange {
  '1day': number;
  '7day': number;
  '30day': number;
}

export interface FloorSale {
  '1day': number;
  '7day': number;
  '30day': number;
}

export interface FloorSaleChange {
  '1day': number;
  '7day': number;
  '30day': number;
}

export interface CollectionWithFloorPrice {
  collection: Collection;
  floorPrice: BigNumber; // WEI
  marketplace: string;
}

export interface CollectionDetail extends Collection {
  floorPrice: {
    value: number;
    change: number;
  };
  callFloor: {
    value: number;
    change: number;
  };
  putFloor: {
    value: number;
    change: number;
  };
  tvl: {
    value: number;
    change: number;
  };
  volume: {
    value: number;
    change: number;
  };
}
// TODO: @hasan, update this to whatever type you are going to have from the end point. Im just using num/string for now to make it work for testing.

export interface StatusResponse {
  success: boolean;
  message?: string;
}

export interface OptionalDataResponse<T> {
  data: T | null;
  message?: string | null;
}

export interface PaginatedResponse<T> {
  hasNextPage: boolean;
  nextPageToken?: string | null;
  items: T[];
}

export interface WithMessage<T> {
  value: undefined | T;
  message: string | undefined;
}

export interface CollectionTokensResponse {
  tokens: NFT[];
}

export interface OptionPricingResponse {
  options: WasabiOption[];
}

export interface OptionPricingBacktestingResponse {
  snapshots: OptionBacktestingSnapshot[];
}

export interface OptionBacktestingSnapshot {
  activeOptionsIssued: number;
  lockedNFTs: number;
  lockedWei: BigNumber;
  timestamp: number;
  totalNFTBalance: number;
  totalNumberTokensCollected: number;
  totalOptionsExercised: number;
  totalOptionsIssued: number;
  totalPremiumsCollected: BigNumber;
  totalStrikeCollected: BigNumber;
  totalWeiBalance: BigNumber;
}

export interface IError {
  message: string;
}

export interface ERC20 {
  name: string;
  symbol: string;
  address: string;
  icon?: string;
}

interface Attribute {
  traitType: string;
  value: any;
}

export interface ERC721Metadata {
  description: string;
  externalUrl: string;
  image: string;
  name: string;
  attributes: Attribute[];
}

export interface AMMOrder {
  collection: string;
  price: BigNumber;
  orderExpiry: number;
}

export interface AMMOrderResponse {
  order: AMMOrder;
  signature: string;
  marketplace?: string;
}

export interface CompetitionEntry {
  address: string;
  numOptionsBought: number;
  numOptionsSold: number;
  points: number;
  pnl: BigNumber;
  volume: BigNumber;
  claimed: boolean;
  name?: string;
  rank: number;
  poolValueLocked: BigNumber;
}

export interface PayoutTransaction {
  hash: string;
  address: string;
  payout: BigNumber;
  profit: BigNumber;
  type: 'SALE' | 'ARBITRAGE';
  optionId: BigNumber;
  duration?: number;
  tweetedOn?: any;
  // externalTokenId?: string;
}

export interface UserStats {
  entry: CompetitionEntry;
  numProfitableTrades: number;
  totalPayout: BigNumber;
  transactions: PayoutTransaction[];
}

export interface TradeHistoryEntry {
  optionId: number;
  optionType: OptionType;
  optionName: string;
  hash: string;
  timestamp: number;
  premium: BigNumber;
  activityType: OptionActivityType;
  payout?: BigNumber;
  profit?: BigNumber;
  tweetedOn?: any;
  payoutTransactionType?: 'SALE' | 'ARBITRAGE';
  duration?: number;
  collectionName: string;
  twitterHandle?: string;
}

export interface PoolHistorySnapshot {
  timestamp: number;
  poolState: PoolState;
  numTotalOptions: number;
  numActiveOptions: number;
  totalPremiumCollected: BigNumber;
  totalStrikeCollected: BigNumber;
  numTotalOptionsExercised: number;
  numTotalOptionsFMVSold: number;
  numTotalOptionsExpired: number;
  numCallOptionsExercised: number;
  numPutOptionsExercised: number;
}
export interface PoolState {
  totalBalance: BigNumber;
  totalTokens: number;
  availableBalance: BigNumber;
  availableTokens: number;
}

export interface FunctionCall {
  to: string;
  value: BigNumber;
  data: string;
}

export interface ArbitrageData {
  functionCalls: FunctionCall[],
  flashLoanAmount: BigNumber;
  tokenId: BigNumber;
  marketValue: BigNumber;
  payout: BigNumber;
  profit: BigNumber;
  signatures: string[];
  marketplace: string;
}

export interface LPTradeHistoryEntry {
  type: OptionActivityType;
  option: WasabiOptionWithMetadata;
  transactionHash: string;
  timestamp: number
}

export interface LPStats {
  pnl: BigNumber;
  tpvl: BigNumber;
  totalPremiumCollected: BigNumber;
  numOptionsIssued: number;
  apr: number;
}

export interface GeofenceData {
  countryCode: string;
  allowed: boolean;
}

export interface OptionOverview {
  type: OptionType;
  expiry: number;
  strike: BigNumber;
  premium: BigNumber;
  numListings: number;
}

export interface WasabiOptionTable {
  expirationDates: ExpirationDate[];
  selectedDate: number;
  floorPrice: BigNumber;
  options: OptionOverview[];
}

export interface EnsSet {
  id: string;
  name: string;
  image: string;
}
