import React, {createContext, FC, useContext, useEffect, useState} from 'react';
import {DEFAULT_SLIPPAGE} from "@/util/constants";

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

export type Timeframe = 1 | 4 | 12 | 24;

export type UserSelections = {
    slippage: number;
    tokenSearchSort?: string;
    side?: 'long' | 'short';
    token?: string;
    market?: string;
    ethPaymentToken?: string;
    usdPaymentToken?: string;
    showAsUsd?: boolean;
    lastNewsFetch?: number; // timestamp
    shownBlastinPepes?: boolean;
    collectGridStyle?: string;
    showChart: boolean;
    showTxTable?: boolean;
    showTrades?: boolean;
    showSidebar?: boolean;
    timeframe: Timeframe;
    hideAddToHomeScreenUntil?: number;
    multipliersUnlocked?: boolean;
}

interface UserSelectionInterface {
    userSelections: UserSelections;
    handleSelection: (key: string, value: any) => void;
}

const defaultUserSelections: UserSelections = {
    slippage: DEFAULT_SLIPPAGE,
    timeframe: 24,
    showChart: true,
    showTrades: true,
};

const UserSelectionContext =
  createContext<UserSelectionInterface>(
    {
        userSelections: defaultUserSelections,
        handleSelection: () => {}
    });

export const UserSelectionProvider : FC<Props> = ({ children}) => {
    const [userSelections, setUserSelections] = useState<UserSelections>(defaultUserSelections);

    useEffect(() => {
        const storedSelections = localStorage.getItem('userSelections');
        if (storedSelections) {
            const savedSelections = JSON.parse(storedSelections);
            if (isNaN(savedSelections.slippage)) {
                savedSelections.slippage = DEFAULT_SLIPPAGE;
            }
            if (savedSelections['showChart'] === undefined) {
                savedSelections.showChart = true;
            }
            if (savedSelections['showTrades'] === undefined) {
                savedSelections.showTrades = true;
            }
            if (savedSelections['timeframe'] === undefined) {
                savedSelections.timeframe = 24;
            }
            setUserSelections(savedSelections);
        }
    }, []);

    const handleSelection = (key: string, value: any) => {
        const updatedSelections = { ...userSelections, [key]: value };
        setUserSelections(updatedSelections);
        localStorage.setItem('userSelections', JSON.stringify(updatedSelections));
    };

    return (
        <UserSelectionContext.Provider value={{ userSelections, handleSelection }}>
            {children}
        </UserSelectionContext.Provider>
    );
};

export const useUserSelection = () => {
    return useContext(UserSelectionContext);
}
